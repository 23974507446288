import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Article from "../components/article"
import PostCard from "../components/postcard"
import SEO from "../components/SEO/seo"

const BlogPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title
  const posts = data.allMarkdownRemark.edges
  return (
    <Layout location={location} title={siteTitle}>
      <SEO
        title="Tri Chu - Blog table of content"
        description="Tri Chu's blog table of content"
      ></SEO>
      <Article>
        <div className="list-group">
          {posts.map(edge => (
            <PostCard key={edge.node.id} post={edge.node}></PostCard>
          ))}
        </div>
      </Article>
    </Layout>
  )
}

export default BlogPage

export const blogQuery = graphql`
  query {
    allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
          }
        }
      }
    }
    site {
      siteMetadata {
        title
      }
    }
  }
`