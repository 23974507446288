import React from "react"
import { Link } from "gatsby"

const PostCard = ({post}) => {
  return (
    <Link
      to={post.fields.slug}
      className="list-group-item list-group-item-action flex-column align-items-start"
    >
      <div className="d-flex w-100 justify-content-between">
        <h5 className="mb-2">{post.frontmatter.title}</h5>
        <small className="text-muted">{post.frontmatter.date}</small>
      </div>
      <p className="mb-1 text-muted">{post.frontmatter.description}</p>
    </Link>
  )
}

export default PostCard